const messages = {
  index: '首页',
  news: '新闻',
  paper: '论文',
  guide: '用户指南',
  login: '登录',
  register: '注册'
}

export default messages
